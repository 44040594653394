import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ImageSlider from './HomePageComponents/sliderImage';
import CustomerAttractionStrip from './HomePageComponents/smallStrip'; 
import BestSellers from './HomePageComponents/BestSeller'; 
import ComboBanner from './HomePageComponents/ComboOfferBanner';
import BestSellerStrip from './HomePageComponents/BestSellerStrip';
import ComboOffers from './HomePageComponents/ComboOffer'; 
import ProductShowcase from './HomePageComponents/ProductShowcase'; 
import Reviews from './HomePageComponents/Reviews'; 
import Footer from './HomePageComponents/Footer'; 
import SingleBanner from './HomePageComponents/SingleBanner'; 
import VideoPlayer from './HomePageComponents/VideoPlayer'; 
import CautionNotice from './HomePageComponents/CautionNotice';
import '../styles/BestSeller.scss';

const Modal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999
      }}
      onClick={onClose}
    >
      <div 
        style={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
          width: '90%',
          margin: '20px'
        }}
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '-12px',
            right: '-12px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: 'white',
            border: '1px solid #e2e8f0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: '#4a5568',
            zIndex: 1
          }}
        >
          ×
        </button>

        <div style={{ padding: '4px' }}>
          <img 
            src="https://ik.imagekit.io/iafgy06va/offer.png?updatedAt=1737543261986"
            alt="Special Offer"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '70vh',
              objectFit: 'contain',
              borderRadius: '4px'
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ModalContainer = ({ children }) => {
  const modalRoot = document.getElementById('modal-root') || document.createElement('div');
  
  useEffect(() => {
    if (!document.getElementById('modal-root')) {
      modalRoot.id = 'modal-root';
      document.body.appendChild(modalRoot);
    }
    
    return () => {
      if (modalRoot.parentElement) {
        modalRoot.parentElement.removeChild(modalRoot);
      }
    };
  }, [modalRoot]);

  return createPortal(children, modalRoot);
};

function HomePage() {
  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const checkAndShowPopup = () => {
      const lastVisited = localStorage.getItem('lastVisitTime');
      const currentTime = new Date().getTime();
      
      // If never visited or if 2 days have passed
      if (!lastVisited || (currentTime - parseInt(lastVisited)) > (2 * 24 * 60 * 60 * 1000)) {
        setIsModalOpen(true);
        localStorage.setItem('lastVisitTime', currentTime.toString());
      }
    };
    
    setShowContent(true);
    
    const modalTimer = setTimeout(() => {
      checkAndShowPopup();
    }, 1000);

    return () => clearTimeout(modalTimer);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const desktopImages = [
    "https://ik.imagekit.io/srwardmxk/website_banner-01_g1GdUzkNA.jpg",
    "https://ik.imagekit.io/srwardmxk/website_banner-05_4D5lSOCtfD.jpg",
    "https://ik.imagekit.io/srwardmxk/website_banner-06_Q6-A4Ed03M.jpg"
  ];

  const mobileImages = [
    "https://ik.imagekit.io/srwardmxk/square_banner-01_lAk238N9mJ.jpg",
    "https://ik.imagekit.io/srwardmxk/square_banner-04_9jVVI2QBkE.jpg",
    "https://ik.imagekit.io/srwardmxk/square_banner-05_b0H63oDpzr.jpg"
  ];

  return (
    <>
      <ModalContainer>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
      </ModalContainer>

      <div className="relative">
        {showContent && (
          <>
            <SingleBanner 
  videoUrl="/Comp1-min.mp4" 
  width="98%"
  height="800px"
/>
            <CustomerAttractionStrip />
            <BestSellerStrip />
            <BestSellers />
            <ImageSlider 
              desktopImages={desktopImages}
              mobileImages={mobileImages}
              interval={5000}
            />
            <ComboBanner />
            <ComboOffers />
            <VideoPlayer/>
            <ProductShowcase />
            <Reviews />
            <CautionNotice />
            <Footer />
          </>
        )}
      </div>
    </>
  );
}

export default HomePage;