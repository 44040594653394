import React, { useState, useEffect } from 'react';
import Footer from './HomePageComponents/Footer';
import { useNavigate } from 'react-router-dom';
import FilterComponent from './ProductPageComponents/FilterComponent';
import ProductCard from './ProductPageComponents/ProductCard';
import '../styles/ProductPage.scss';
import instance from '../helpers/axiosconfig';
import CartSidebar from './CartPages/CartPage';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('price-asc');
  const [filterCategory, setFilterCategory] = useState('All Products');
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState(2000);
  const [isCartOpen, setIsCartOpen] = useState(false);  
  const [cart, setCart] = useState([]);
  const [volumeRange, setVolumeRange] = useState('All');
  const [fragrance, setFragrance] = useState('All');
  const [availability, setAvailability] = useState('All');
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('All Products');
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const categoryFromURL = params.get('category');
    
    if (categoryFromURL) {
      const decodedCategory = decodeURIComponent(categoryFromURL);
      setFilterCategory(decodedCategory);
      setPageTitle(decodedCategory);
    } else {
      setFilterCategory('All Products');
      setPageTitle('All Products');
    }
  }, [window.location.search]);

  useEffect(() => {
    setPageTitle(filterCategory);
  }, [filterCategory]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      
      const processedProducts = [];
      response.data.forEach(product => {
        product.variations.forEach(variation => {
          processedProducts.push({
            _id: product._id,
            category: product.category,
            baseDescription: product.baseDescription,
            variations: [variation],
          });
        });
      });
      
      setProducts(processedProducts);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
      console.error('Error fetching products:', err);
    }
  };

  const doesProductMatchFilters = (product) => {
    if (!product || !product.variations || !product.variations[0]) {
      return false;
    }

    const variation = product.variations[0];
    
    const isDefaultFilters = 
      filterCategory === 'All Products' && 
      priceRange === 2000 && 
      volumeRange === 'All' &&
      fragrance === 'All' &&
      availability === 'All' && 
      !searchTerm;

    if (isDefaultFilters) {
      return true;
    }

    const productCategory = product.category?.toLowerCase().trim() || '';
    const filterCategoryLower = filterCategory.toLowerCase().trim();
    
    const matchesCategory = 
      filterCategory === 'All Products' || 
      productCategory.includes(filterCategoryLower) || 
      filterCategoryLower.includes(productCategory);

    const searchTermLower = searchTerm.toLowerCase().trim();
    const matchesSearch = !searchTerm || 
      variation.name.toLowerCase().includes(searchTermLower) ||
      productCategory.includes(searchTermLower) ||
      variation.fragrance?.toLowerCase().includes(searchTermLower) ||
      variation.volume?.toLowerCase().includes(searchTermLower);

    const discountedPrice = variation.price * (1 - (variation.discount || 0) / 100);
    const matchesPrice = discountedPrice <= priceRange;

    const matchesVolume = 
      volumeRange === 'All' || 
      variation.volume === volumeRange;

    const matchesFragrance = 
      fragrance === 'All' || 
      variation.fragrance?.toLowerCase() === fragrance.toLowerCase();

    const matchesAvailability = 
      availability === 'All' || 
      (availability === 'In Stock' && variation.stock > 0) ||
      (availability === 'Out of Stock' && variation.stock === 0);

    return matchesCategory && 
           matchesSearch && 
           matchesPrice && 
           matchesVolume && 
           matchesFragrance && 
           matchesAvailability;
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const clearAllFilters = () => {
    setFilterCategory('All Products');
    setSearchTerm('');
    setPriceRange(2000);
    setVolumeRange('All');
    setFragrance('All');
    setAvailability('All');
    navigate('/products', { replace: true });
  };

  const getFilterOptions = () => {
    const volumes = new Set(['All']);
    const fragrances = new Set(['All']);
    const categories = new Set(['All Products']);
    
    products.forEach(product => {
      const variation = product.variations[0];
      if (variation.volume) volumes.add(variation.volume);
      if (variation.fragrance) fragrances.add(variation.fragrance);
      if (product.category) categories.add(product.category);
    });

    return {
      volumes: Array.from(volumes).sort(),
      fragrances: Array.from(fragrances).sort(),
      categories: Array.from(categories).sort()
    };
  };

  const sortProducts = (products) => {
    return [...products].sort((a, b) => {
      const aVariation = a.variations[0];
      const bVariation = b.variations[0];
      
      if (sortBy === 'name') {
        return aVariation.name.localeCompare(bVariation.name);
      }
      
      const aPrice = aVariation.price * (1 - (aVariation.discount || 0) / 100);
      const bPrice = bVariation.price * (1 - (bVariation.discount || 0) / 100);
      
      if (sortBy === 'price-asc') {
        return aPrice - bPrice;
      }
      if (sortBy === 'price-desc') {
        return bPrice - aPrice;
      }
      return 0;
    });
  };

  const addToCart = (cartItem) => {
    const existingItemIndex = cart.findIndex(item => 
      item.productId === cartItem.productId && 
      item.variationId === cartItem.variationId
    );
    
    let updatedCart;
    if (existingItemIndex > -1) {
      updatedCart = cart.map((item, index) => 
        index === existingItemIndex 
          ? { ...item, quantity: item.quantity + cartItem.quantity }
          : item
      );
    } else {
      updatedCart = [...cart, cartItem];
    }
    
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    toast.success(`${cartItem.name} added to cart`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'custom-toast',
      progressClassName: 'custom-toast-progress',
      icon: '🛒'
    });
  
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const handleProductClick = (productId, variationId) => {
    navigate(`/product/${productId}`, { 
      state: { selectedVariationId: variationId }
    });
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    document.body.style.overflow = !isMobileFilterOpen ? 'hidden' : 'auto';
  };

  const updateCategoryAndURL = (category) => {
    setFilterCategory(category);
    setPageTitle(category);
    
    const params = new URLSearchParams(window.location.search);
    if (category === 'All Products') {
      params.delete('category');
    } else {
      params.set('category', category);
    }
    navigate(`/products?${params.toString()}`, { replace: true });
  };

  const filteredProducts = products.filter(doesProductMatchFilters);
  const filteredAndSortedProducts = sortProducts(filteredProducts);
  const filterOptions = getFilterOptions();

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="product-page">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true}
        theme="colored"
        toastStyle={{ 
          background: '#066469',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(6, 100, 105, 0.3)'
        }}
        progressStyle={{ background: 'rgba(255, 255, 255, 0.4)' }}
        closeButton={false}
        icon={true}
        limit={3}
        transition={Slide}
      />
      
      <header className="header">
        <h1>{pageTitle}</h1>
        <button 
          className="mobile-filter-toggle" 
          onClick={toggleMobileFilter}
          aria-label={isMobileFilterOpen ? "Close filters" : "Open filters"}
        >
          {isMobileFilterOpen ? '✕' : '☰'}
        </button>
      </header>
      
      <main className="main-content">
        <div className={`filter-container ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
          <FilterComponent
            filterCategory={filterCategory}
            setFilterCategory={updateCategoryAndURL}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            volumeRange={volumeRange}
            setVolumeRange={setVolumeRange}
            fragrance={fragrance}
            setFragrance={setFragrance}
            availability={availability}
            setAvailability={setAvailability}
            filterOptions={filterOptions}
            isMobileFilterOpen={isMobileFilterOpen}
            onClose={() => setIsMobileFilterOpen(false)}
            onSearchClear={clearSearch}
            onClearAll={clearAllFilters}
          />
        </div>
        
        <div className="product-area">
          <div className="search-sort">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search products"
              />
              {searchTerm && (
                <button 
                  className="clear-search"
                  onClick={clearSearch}
                  aria-label="Clear search"
                >
                  ✕
                </button>
              )}
              <span className="search-icon" aria-hidden="true">🔍</span>
            </div>
            <div className="sort-dropdown">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                aria-label="Sort products"
              >
                <option value="name">Name</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="product-grid">
            {filteredAndSortedProducts.length === 0 ? (
              <div className="no-products">
                <p>No products found matching your criteria.</p>
                <button 
                  onClick={clearAllFilters}
                  className="clear-filters-btn"
                >
                  Clear all filters
                </button>
              </div>
            ) : (
              filteredAndSortedProducts.map((product) => (
                <ProductCard
                  key={`${product._id}-${product.variations[0]._id}`}
                  product={product}
                  onAddToCart={addToCart}
                  onProductClick={handleProductClick}
                  onOpenCart={() => setIsCartOpen(true)}
                />
              ))
            )}
          </div>
        </div>
      </main>
      
      <Footer />
      
      <CartSidebar 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />
      
      {isMobileFilterOpen && (
        <div 
          className="mobile-overlay" 
          onClick={toggleMobileFilter}
          aria-hidden="true"
        />
      )}
    </div>
  );
}

export default ProductPage;