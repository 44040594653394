import React from 'react';
import { MapPin, Phone, Mail, Clock, Package } from 'lucide-react';
import '../../styles/About.scss';

const AboutPage = () => {
  return (
    <div className="about-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>About Periodic Industries</h1>
        <p>
          Welcome to Periodic Industries, your trusted partner in quality products and exceptional service. 
          We take pride in delivering excellence to our customers across India and beyond.
        </p>
      </div>

      {/* Mission Section */}
      <div className="mission-section">
        <h2>Our Mission</h2>
        <div className="mission-grid">
          <div className="mission-item">
            <Package className="icon" />
            <h3>Quality Products</h3>
            <p>Delivering premium products that meet the highest standards of quality and reliability.</p>
          </div>
          <div className="mission-item">
            <Clock className="icon" />
            <h3>Timely Delivery</h3>
            <p>Ensuring your orders reach you within 0-7 days through our efficient shipping network.</p>
          </div>
          <div className="mission-item">
            <Mail className="icon" />
            <h3>Customer Support</h3>
            <p>Providing dedicated support to address all your queries and concerns.</p>
          </div>
        </div>
      </div>

      {/* Contact Information */}
      <div className="info-grid">
        <div className="contact-section">
          <h2>Contact Us</h2>
          <div className="contact-items">
            <div className="contact-item">
              <MapPin className="icon" />
              <div className="contact-content">
                <h3>Address</h3>
                <p>PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD,<br />Jamnagar, Gujarat, 361006</p>
              </div>
            </div>
            <div className="contact-item">
              <Phone className="icon" />
              <div className="contact-content">
                <h3>Phone</h3>
                <p>7788994668</p>
              </div>
            </div>
            <div className="contact-item">
              <Mail className="icon" />
              <div className="contact-content">
                <h3>Email</h3>
                <p>CARE@BUYMALAK.IN</p>
              </div>
            </div>
          </div>
        </div>

        <div className="shipping-section">
          <h2>Shipping Information</h2>
          <div className="shipping-items">
            <div className="shipping-item">
              <h3>Domestic Shipping</h3>
              <p>We offer reliable domestic shipping through registered courier services and speed post across India.</p>
            </div>
            <div className="shipping-item">
              <h3>International Shipping</h3>
              <p>Our international shipping services ensure your products reach you safely, wherever you are in the world.</p>
            </div>
            <div className="shipping-item">
              <h3>Delivery Timeline</h3>
              <p>Standard delivery time: 0-7 days<br />
              Custom delivery dates available upon agreement</p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="cta-section">
        <h2>Ready to Get Started?</h2>
        <p>Browse our collection and experience the quality of Periodic Industries products.</p>
        <button className="cta-button">Shop Now</button>
      </div>
    </div>
  );
};

export default AboutPage;