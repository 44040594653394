import React from 'react';
import { AlertTriangle } from 'lucide-react';
import '../../styles/CautionNotice.css'

const CautionNotice = () => {
  return (
    <div className="caution-notice">
      <AlertTriangle size={20} className="caution-notice__icon" />
      <p className="caution-notice__text">
      We urge you to remain vigilant against the rising threat of cybercrime, particularly fraudulent communications via phone, SMS, WhatsApp, email, and other channels by individuals falsely representing our company. Please note that we will never request personal or payment details through these methods. Always confirm the authenticity of communications through our verified contact channels. If you are uncertain about any interaction, contact us directly for verification. Your awareness and caution are crucial in safeguarding your personal information.
      </p>
    </div>
  );
};

export default CautionNotice;