import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';
import { useCart } from '../../utils/CartContext';
import { toast } from 'react-toastify';
import AddressForm from './addressForm';
import '../../styles/AddressCheckout.scss';
import 'react-toastify/dist/ReactToastify.css';

const AmazonPaymentCheckout = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [showAddressForm, setShowAddressForm] = useState(false);
  const { clearCart } = useCart();

  // Calculate prices based on payment method
  const calculatePrices = (baseTotal) => {
    const prepaidDiscount = baseTotal * 0.05; // 5% discount
    const codCharges = 25; // ₹25 delivery charge

    return {
      prepaid: baseTotal - prepaidDiscount,
      cod: baseTotal + codCharges,
      prepaidSavings: prepaidDiscount,
      codExtra: codCharges
    };
  };

  useEffect(() => {
    try {
      const savedCheckoutData = localStorage.getItem('checkoutData');
      
      if (!savedCheckoutData) {
        toast.error('No checkout data found');
        navigate('/');
        return;
      }
  
      const parsedData = JSON.parse(savedCheckoutData);
      
      if (!parsedData.cart || !parsedData.pricing || !parsedData.address || parsedData.cart.length === 0) {
        toast.error('Invalid checkout data');
        navigate('/');
        return;
      }
  
      setCheckoutData(parsedData);
    } catch (error) {
      console.error('Error loading checkout data:', error);
      toast.error('Error loading checkout data');
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleAddressSubmit = (newAddress) => {
    // Convert AddressForm format to checkout format
    const formattedAddress = {
      name: newAddress.fullName,
      phone: newAddress.phone,
      email: newAddress.email,
      street: `${newAddress.address1}${newAddress.address2 ? ', ' + newAddress.address2 : ''}`,
      city: newAddress.city,
      state: newAddress.state,
      pincode: newAddress.pincode,
      type: newAddress.type
    };

    // Update checkout data with new address
    const updatedCheckoutData = {
      ...checkoutData,
      address: formattedAddress
    };

    // Update local storage and state
    localStorage.setItem('checkoutData', JSON.stringify(updatedCheckoutData));
    setCheckoutData(updatedCheckoutData);
    setShowAddressForm(false);
    toast.success('Address updated successfully');
  };

  const handlePaymentSelection = (method) => {
    if (method === 'cod' && checkoutData?.pricing?.total > 10000) {
      return;
    }
    setSelectedPayment(method);
  };

  const initializeRazorpayCheckout = async () => {
    try {
      setIsProcessing(true);
      
      // Reset processing state if modal closes without completing payment
      const handleModalClose = () => {
        setIsProcessing(false);
        setSelectedPayment('');
      };
      
      const items = checkoutData.cart.map(item => ({
        productId: item.productId,
        variationId: item.variationId,
        quantity: item.quantity,
        price: item.price
      }));
  
      const prices = calculatePrices(checkoutData.pricing.total);
      const finalAmount = selectedPayment === 'prepaid' ? prices.prepaid : prices.cod;
  
      const response = await axios.post('/magic-checkout/create', {
        items,
        shippingAddress: checkoutData.address,
        totalAmount: finalAmount
      });
  
      const razorpayOptions = {
        ...response.data.razorpayConfig,
        handler: async function(response) {
          try {
            const verificationResponse = await axios.post('/order/verify', {
              razorpayOrderId: response.razorpay_order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpaySignature: response.razorpay_signature
            });
  
            if (verificationResponse.data.success) {
              clearCart();
              toast.success('Payment successful!');
              navigate('/checkout/order-success', {
                state: {
                  orderId: verificationResponse.data.order.id,
                  paymentId: response.razorpay_payment_id
                }
              });
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            toast.error('Payment verification failed');
            navigate('/checkout/failed');
          } finally {
            setIsProcessing(false);
          }
        }
      };
  
      const razorpayInstance = new window.Razorpay({
        ...razorpayOptions,
        modal: {
          ondismiss: handleModalClose
        }
      });
      razorpayInstance.open();
  
    } catch (error) {
      console.error('Checkout error:', error);
      toast.error(error.message || 'Checkout failed');
      setIsProcessing(false);
    }
  };

  const handleCODCheckout = async () => {
    try {
      setIsProcessing(true);
      
      const items = checkoutData.cart.map(item => ({
        productId: item.productId,
        variationId: item.variationId,
        quantity: item.quantity,
        price: item.price,
        name: item.name,
        description: item.description || item.name,
        imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl[0] : item.imageUrl,
        sku: `SKU-${item.productId}-${item.variationId || ''}`
      }));

      const prices = calculatePrices(checkoutData.pricing.total);
  
      const response = await axios.post('/order/create-cod', {
        items,
        shippingAddress: checkoutData.address,
        totalAmount: prices.cod,
        couponCode: checkoutData.coupon?.code
      });
  
      if (response.data.success) {
        // Clear cart data using context
        clearCart();
        
        toast.success('Order placed successfully! You can pay at the time of delivery.');
        navigate('/checkout/order-success', {
          state: {
            orderId: response.data.orderId,
            paymentId: 'COD'
          }
        });
      }
    } catch (error) {
      console.error('COD checkout error:', error);
      toast.error(error.message || 'Failed to place order. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubmit = () => {
    if (!selectedPayment) {
      toast.error('Please select a payment method');
      return;
    }
  
    if (isProcessing) {
      return;
    }
  
    if (selectedPayment === 'prepaid') {
      initializeRazorpayCheckout();
    } else if (selectedPayment === 'cod') {
      handleCODCheckout();
    }
  };

  const AddressSection = ({ address }) => {
    if (!address) return null;
  
    return (
      <div className="checkout-section">
        <div className="section-header">
          <span className="section-number">1</span>
          <h2>Delivery address</h2>
          <button 
            className="change-button"
            onClick={() => setShowAddressForm(true)}
          >
            Change
          </button>
        </div>
        <div className="section-content">
          <div className="address-details">
            <div className="address-type">{address.type}</div>
            <p>{address.name}</p>
            <p>{address.street}</p>
            <p>{address.city}, {address.state} {address.pincode}</p>
            <p>Phone: {address.phone}</p>
            {address.email && <p>Email: {address.email}</p>}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div className="loading">Loading checkout...</div>;
  }

  if (!checkoutData) {
    return null;
  }

  const prices = calculatePrices(checkoutData.pricing.total);

  return (
    <div className="checkout-page">
      {showAddressForm && (
        <AddressForm
          onSubmit={handleAddressSubmit}
          onClose={() => setShowAddressForm(false)}
          existingAddresses={JSON.parse(localStorage.getItem('addresses') || '[]')}
        />
      )}
      
      <div className="checkout-container">
        <div className="amazon-logo">
          <img src="/malak.png" alt="Amazon" />
          <span className="checkout-text">Checkout</span>
          <span className="lock-icon">🔒</span>
        </div>

        <div className="checkout-grid">
          <div className="main-content">
            <AddressSection address={checkoutData?.address} />

            {/* Payment Method Section */}
            <div className="checkout-section">
              <div className="section-header">
                <span className="section-number">2</span>
                <h2>Select a payment method</h2>
              </div>
              <div className="section-content">
                <div className="payment-methods">
                  <div className="payment-options">
                    <h3>Select payment method</h3>
                    <div className="payment-option">
                      <input
                        type="radio"
                        id="credit-card"
                        name="payment"
                        value="prepaid"
                        checked={selectedPayment === 'prepaid'}
                        onChange={() => handlePaymentSelection('prepaid')}
                      />
                      <label htmlFor="credit-card">
                        <div className="payment-method-content">
                          <div className="payment-method-title">
                            Credit, debit, netbanking or UPI
                          </div>
                          <div className="card-icons">
                            <img src="/VISA CARD.png" alt="Visa" />
                            <img src="/Master Card.webp" alt="Mastercard" />
                            <img src="/AmericaNE.webp" alt="American Express" />
                            <img src="/Netbanking.png" alt="Netbanking" />
                            <img src="/UPI.webp" alt="UPI" />
                          </div>
                        </div>
                        <div className="payment-price-info">
                          <span className="final-price">₹{prices.prepaid.toLocaleString('en-IN')}</span>
                          <span className="price-tag">5% off</span>
                          <span className="savings-note">Save ₹{prices.prepaidSavings.toLocaleString('en-IN')}</span>
                        </div>
                      </label>
                    </div>

                    <div className="payment-option">
                      <input
                        type="radio"
                        id="cod"
                        name="payment"
                        value="cod"
                        checked={selectedPayment === 'cod'}
                        onChange={() => handlePaymentSelection('cod')}
                        disabled={checkoutData.pricing.total > 10000}
                      />
                      <label htmlFor="cod">
                        <div className="payment-method-content">
                          <div className="payment-method-title">
                            Cash on Delivery/Pay on Delivery
                          </div>
                          {checkoutData.pricing.total > 10000 && (
                            <span className="unavailable-text">Unavailable for this payment</span>
                          )}
                        </div>
                        <div className="payment-price-info">
                          <span className="final-price">₹{prices.cod.toLocaleString('en-IN')}</span>
                          <span className="price-tag extra-charge">+₹{prices.codExtra} delivery charges</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <button 
                    className="use-payment-button"
                    onClick={handleSubmit}
                    disabled={!selectedPayment || isProcessing}
                  >
                    {isProcessing ? 'Processing...' : `Use this payment method`}
                  </button>
                </div>
              </div>
            </div>

            {/* Items Section */}
            <div className="checkout-section">
              <div className="section-header">
                <span className="section-number">3</span>
                <h2>Items and delivery</h2>
              </div>
              <div className="section-content">
                <div className="order-items">
                  {checkoutData.cart.map((item) => (
                    <div key={`${item.productId}-${item.variationId || ''}`} className="order-item">
                      <img 
                        src={Array.isArray(item.imageUrl) && item.imageUrl[0] ? item.imageUrl[0] : 
                            typeof item.imageUrl === 'string' ? item.imageUrl : '/placeholder-image.jpg'} 
                        alt={item.name}
                        className="product-image"
                        onError={(e) => {
                          e.target.src = '/placeholder-image.jpg';
                        }}
                      />
                      <div className="item-details">
                        <h3>{item.name}</h3>
                        {item.variationName && <p>{item.variationName}</p>}
                        <p>Quantity: {item.quantity}</p>
                        <p>₹{item.price.toLocaleString('en-IN')}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="order-summary">
            <button 
              className="place-order-button"
              onClick={handleSubmit}
              disabled={!selectedPayment || isProcessing}
            >
              {isProcessing ? 'Processing...' : `Pay ${selectedPayment === 'prepaid' ? 
                `₹${prices.prepaid.toLocaleString('en-IN')}` : 
                selectedPayment === 'cod' ? `₹${prices.cod.toLocaleString('en-IN')}` : 
                `₹${checkoutData.pricing.total.toLocaleString('en-IN')}`}`}
            </button>
            <p className="order-note">
              Choose a payment method to continue checking out. You will still have a chance to review and edit your order before it is final.
            </p>

            <div className="summary-details">
              <h2>Order Summary</h2>
              <div className="summary-row">
                <span>Items:</span>
                <span>₹{checkoutData.pricing.subtotal.toLocaleString('en-IN')}</span>
              </div>
              <div className="summary-row">
                <span>Delivery:</span>
                <span>{selectedPayment === 'cod' ? `₹${prices.codExtra}` : 'FREE'}</span>
              </div>
              {checkoutData.coupon && (
                <div className="summary-row">
                  <span>Coupon Discount:</span>
                  <span>-₹{checkoutData.pricing.discount.toLocaleString('en-IN')}</span>
                </div>
              )}
              {selectedPayment === 'prepaid' && (
                <div className="summary-row discount">
                  <span>Prepaid Discount (5%):</span>
                  <span>-₹{prices.prepaidSavings.toLocaleString('en-IN')}</span>
                </div>
              )}
              <div className="summary-row order-total">
                <span>Order Total:</span>
                <span>₹{(selectedPayment === 'prepaid' ? prices.prepaid : 
                       selectedPayment === 'cod' ? prices.cod : 
                       checkoutData.pricing.total).toLocaleString('en-IN')}</span>
              </div>
              <a href="#" className="delivery-info">How are delivery costs calculated?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazonPaymentCheckout;