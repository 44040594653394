import React, { useState, useRef, useEffect } from 'react';
import { Volume2, VolumeX, Play, Pause, Maximize } from 'lucide-react';

const VideoPlayer = ({ src = "https://ik.imagekit.io/iafgy06va/Video.mp4?tr=orig" }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.video-outer-container {
  padding: 2px;
  background: linear-gradient(45deg, #2a2a2a, #3a3a3a);
  border-radius: 14px;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.video-player-container {
  position: relative;
  width: 100%;
  background: #1a1a1a;
  border-radius: 12px;
  overflow: hidden;
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.controls-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    transparent 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-player-container:hover .controls-overlay,
.video-player-container:active .controls-overlay {
  opacity: 1;
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.controls-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.controls-right {
  display: flex;
  align-items: center;
}

.control-button {
  background: transparent;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}

.volume-button {
  position: relative;
  display: flex;
  align-items: center;
}

.volume-slider {
  width: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  transition: width 0.2s ease;
  overflow: hidden;
  margin-left: 8px;
}

.volume-button:hover .volume-slider {
  width: 80px;
}

.progress-bar {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transform: scaleY(0.7);
  transition: transform 0.2s ease;
}

.progress-bar:hover {
  transform: scaleY(1);
}

.progress-filled {
        height: 100%;
        background: #fff;
        transition: width 0.1s linear;
      }

.big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.big-play-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) scale(1.1);
}

.mute-indicator {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
  align-items: center;
  gap: 6px;
  z-index: 30;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mute-indicator:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .video-outer-container {
          width: 90%;
          max-width: 400px;
          margin: 10px auto;
          border-radius: 10px;
          padding: 1px;
        }

        .video-player-container {
          border-radius: 8px;
        }

        .progress-bar {
          bottom: 20px;
        }

        .progress-filled {
          background: rgba(255, 255, 255, 0.5); /* Make progress bar semi-transparent on mobile */
        }

        .controls-overlay {
          padding: 12px;
          opacity: 1;
        }

  .volume-slider {
    display: none;
  }

  .mute-indicator {
    display: flex;
  }

  .control-button {
    padding: 12px;
  }

  .controls-container {
    padding: 0 8px;
  }

  .big-play-button {
    width: 64px;
    height: 64px;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .video-outer-container {
    width: 85%;
    max-width: 800px;
    margin: 15px auto;
  }
}

/* Touch Device Styles */
@media (hover: none) {
  .controls-overlay {
    opacity: 1;
  }
  
  .control-button:hover {
    transform: none;
    background: transparent;
  }
  
  .big-play-button:hover {
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.2);
  }
}

/* Fullscreen Styles */
.video-player-container:fullscreen {
  width: 100vw;
  height: 100vh;
  background: black;
}

.video-player-container:fullscreen .video-wrapper {
  height: 100vh;
}

.video-player-container:fullscreen .video-element {
  object-fit: contain;
}
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleWaiting = () => setIsBuffering(true);
    const handlePlaying = () => {
      setIsBuffering(false);
      setIsLoading(false);
    };
    const handleLoadStart = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('playing', handlePlaying);
    video.addEventListener('loadstart', handleLoadStart);
    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('playing', handlePlaying);
      video.removeEventListener('loadstart', handleLoadStart);
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (videoRef.current) {
              videoRef.current.play()
                .then(() => setIsPlaying(true))
                .catch(error => console.log("Play failed:", error));
            }
          } else {
            if (videoRef.current) {
              videoRef.current.pause();
              setIsPlaying(false);
            }
          }
        });
      },
      {
        threshold: 0.5
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const handleProgressClick = (e) => {
    const progressBar = e.currentTarget;
    const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
    if (videoRef.current) {
      videoRef.current.currentTime = clickPosition * videoRef.current.duration;
    }
  };

  const LoadingSpinner = () => (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );

  return (
    <div className="video-outer-container">
      <div className="video-player-container" ref={containerRef}>
        <div className="video-wrapper">
          <video
            ref={videoRef}
            src={src}
            className="video-element"
            onClick={togglePlay}
            onTimeUpdate={handleTimeUpdate}
            muted={isMuted}
            playsInline
          />
          
          {(isLoading || isBuffering) && <LoadingSpinner />}

          <div className="mute-indicator" onClick={toggleMute}>
            {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
            {isMuted ? 'Unmute' : 'Mute'}
          </div>

          <div className="controls-overlay">
            <div className="progress-bar" onClick={handleProgressClick}>
              <div 
                className="progress-filled"
                style={{ width: `${progress}%` }}
              />
            </div>

            <div className="controls-container">
              <div className="controls-left">
                <button className="control-button" onClick={togglePlay}>
                  {isPlaying ? (
                    <Pause size={24} />
                  ) : (
                    <Play size={24} />
                  )}
                </button>
                
                <div className="volume-button">
                  <button className="control-button" onClick={toggleMute}>
                    {isMuted ? (
                      <VolumeX size={24} />
                    ) : (
                      <Volume2 size={24} />
                    )}
                  </button>
                  <div className="volume-slider">
                    <div className="volume-filled" style={{ width: isMuted ? '0%' : '100%' }}></div>
                  </div>
                </div>
              </div>

              <div className="controls-right">
                <button className="control-button" onClick={toggleFullscreen}>
                  <Maximize size={24} />
                </button>
              </div>
            </div>
          </div>

          {!isPlaying && !isLoading && !isBuffering && (
            <div className="big-play-button" onClick={togglePlay}>
              <Play size={32} color="white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;