import React, { useState } from 'react';
import '../../styles/Footer.scss';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscription = (e) => {
    if (validateEmail(email)) {
      setSubscribed(true);
      setError('');
      setEmail('');
      setTimeout(() => setSubscribed(false), 3000);
    } else {
      setError('Please enter a valid email address');
      setSubscribed(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubscription();
  };

  const handleSubscribe = () => {
    handleSubscription();
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          {/* Brand Section */}
          <div className="footer__section">
            <img src="/malak-removebg-preview.jpg" alt="PUER Logo" className="footer__logo" />
            <p className="footer__newsletter-text">
              Sign up to our newsletter to receive exclusive offers.
            </p>
            <form onSubmit={handleSubmit} className="footer__form">
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError('');
                  setSubscribed(false);
                }}
                placeholder="E-mail"
                className="footer__input"
              />
              <button type="submit" className="footer__submit">
                <i className="fa-solid fa-paper-plane"></i>
              </button>
            </form>
            {error && <p className="footer__error">{error}</p>}
            {subscribed && <p className="footer__success">Subscribed!</p>}
            <button 
              onClick={handleSubscribe}
              className="footer__subscribe-button"
              type="button"
            >
              Subscribe Now
            </button>
            <div className="footer__social">
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-pinterest-p"></i>
              </a>
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
              <a href="#" className="footer__social-link">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </div>
          </div>

          {/* Also available on */}
          <div className="footer__section">
            <h3 className="footer__heading">Also available on</h3>
            <ul className="footer__list">
              <li><a href="#">Amazon</a></li>
              <li><a href="#">Flipkart</a></li>
              {/* <li><a href="#">Bigbasket</a></li>
              <li><a href="#">Blinkit</a></li> */}
              <li><a href="#">Jiomart</a></li>
            </ul>
          </div>

          {/* Brand Information */}
          <div className="footer__section">
            <h3 className="footer__heading">Brand Information</h3>
            <ul className="footer__list">
            <li><a href="/about">About Us</a></li>
    <li><a href="/privacy">Privacy policy</a></li>
    <li><a href="/privacy">Return and Refund policy</a></li>
    <li><a href="/terms">Terms Of Service</a></li>
    <li><a href="/contact">Contact Us</a></li>
              {/* <li><a href="#">FAQ</a></li> */}
              {/* <li><a href="#">Blogs</a></li> */}
            </ul>
          </div>

          {/* Customer Care */}
          <div className="footer__section">
            <h3 className="footer__heading">Customer care</h3>
            <ul className="footer__list">
              <li>Email us: CARE@BUYMALAK.IN</li>
              <li>Call us: +91 7788 9946 68</li>
              <li>WhatsApp: +91 778 899 4668</li>
              <li className="footer__time">Monday - Saturday: 10.00 AM to 6:00 PM</li>
            </ul>
            <div className="footer__address">
              <p className="footer__address-heading">Corporate Office:</p>
              <p>Periodic Industries.,</p>
              <p>PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD,</p>
              <p>Jamnagar, Gujrat, 361006</p>
            </div>
            <div className="footer__address">
              {/* <p className="footer__address-heading">Registered Office:</p>
              <p>, 361006 Jamnagar GUJARAT </p>
              <p>GUJRAT, 360006, India</p> */}
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <p>Copyright Ⓒ Periodic Industries - All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;