
import axios from "axios";

const instance = axios.create({
//  baseURL: "http://localhost:3000/api/", 
 baseURL: "/api/",
  
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;


