// FilterComponent.jsx
import React from 'react';
import '../../styles/FilterComponent.scss';

const FilterComponent = ({
  filterCategory,
  setFilterCategory,
  priceRange,
  setPriceRange,
  volumeRange,
  setVolumeRange,
  fragrance,
  setFragrance,
  availability,
  setAvailability,
  filterOptions,
  isMobileFilterOpen,
  onClose,
  onSearchClear,
  onClearAll
}) => {
  return (
    <>
      <div className={`filter-wrapper ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
        <div className="filter-component">
          <div className="filter-header">
            <h2 className="filter-title">
              <span className="filter-icon"></span>
              Filters
            </h2>
            <div className="filter-actions">
              <button className="clear-all" onClick={onClearAll}>
                Clear All
              </button>
              <button className="close-button" onClick={onClose} aria-label="Close filters">
                ×
              </button>
            </div>
          </div>

          <div className="filter-content">
            <div className="filter-group">
              <label htmlFor="category">Category</label>
              <select 
                id="category"
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                {filterOptions?.categories?.map(category => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="volume">Volume</label>
              <select
                id="volume"
                value={volumeRange}
                onChange={(e) => setVolumeRange(e.target.value)}
              >
                {filterOptions?.volumes?.map(volume => (
                  <option key={volume} value={volume}>
                    {volume}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="fragrance">Fragrance</label>
              <select
                id="fragrance"
                value={fragrance}
                onChange={(e) => setFragrance(e.target.value)}
              >
                {filterOptions?.fragrances?.map(frag => (
                  <option key={frag} value={frag}>
                    {frag}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="price">Price Range: ₹{priceRange}</label>
              <input 
                id="price"
                type="range" 
                min="0" 
                max="2000"
                value={priceRange}
                onChange={(e) => setPriceRange(Number(e.target.value))}
              />
              <div className="range-labels">
                <span>₹0</span>
                <span>₹2000</span>
              </div>
            </div>

            <div className="filter-group">
  <label htmlFor="category">Category</label>
  <select 
    id="category"
    value={filterCategory}
    onChange={(e) => setFilterCategory(e.target.value)}
  >
    {filterOptions?.categories?.map(category => (
      <option 
        key={category} 
        value={category === 'All Products' ? category : category.toUpperCase()}
      >
        {category === 'All Products' ? category : category.toUpperCase()}
      </option>
    ))}
  </select>
</div>
          </div>
        </div>
      </div>
      {isMobileFilterOpen && <div className="filter-overlay" onClick={onClose} />}
    </>
  );
};

export default FilterComponent;