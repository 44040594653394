import React, { useState } from 'react';
import { BsCartPlus, BsLightningCharge, BsCheckCircleFill, BsExclamationCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useAuth } from '../Signin_up/AuthContext';
import '../../styles/ProductCard.scss';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';
import Bought from './ProductEngagement'



const ProductCard = ({ product, onProductClick }) => {
  const { addToCart } = useCart();
  const { openCart } = useCartUI();
  const [isLoading, setIsLoading] = useState(false);
  const defaultVariation = product.variations[0];
  
  const {
    _id,
    category,
    baseDescription
  } = product;

  const {
    name,
    description,
    imageUrls,
    price,
    stock,
    discount,
    fragrance,
    volume,
    _id: variationId
  } = defaultVariation;

  const isInStock = stock > 0;
  const formattedPrice = price.toLocaleString('en-IN');
  const discountedPrice = price - (price * discount / 100);
  const formattedDiscountedPrice = discountedPrice.toLocaleString('en-IN');
  const hasOtherVariations = product.variations.length > 1;
  const showDetails = category === "SURFACE CARE" && (volume || (fragrance && fragrance !== "Na"));

  const handleAddToCart = async () => {
    if (!isInStock) {
      toast.warning('Product is out of stock');
      return false;
    }
    
    const cartItem = {
      productId: _id,
      variationId: variationId,
      name,
      price: Number(discount > 0 ? discountedPrice : price),
      originalPrice: Number(price),
      imageUrl: imageUrls,
      volume,
      fragrance,
      quantity: 1
    };
    
    try {
      addToCart(cartItem); // Use hook's addToCart directly
      toast.success('Added to cart');
      return true;
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to cart');
      return false;
    }
  };

  const handleBuyNow = async () => {
    if (!isInStock) {
      toast.error('Product is out of stock');
      return;
    }

    setIsLoading(true);
    try {
      const success = await handleAddToCart();
      if (success) {
        openCart();  // Use the context's openCart function
      }
    } catch (error) {
      console.error('Error in buy now flow:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStockBadge = () => {
    if (stock > 10) {
      return (
        <div className="badge in-stock">
          <BsCheckCircleFill className="badge-icon" />
          {/* <span>In Stock</span> */}
        </div>
      );
    } else if (stock > 0) {
      return (
        <div className="badge low-stock">
          <BsExclamationCircle className="badge-icon" />
          <span>Only {stock} left</span>
        </div>
      );
    }
    return (
      <div className="badge out-stock">
        <BsExclamationCircle className="badge-icon" />
        <span>Out of Stock</span>
      </div>
    );
  };

  const handleProductClick = () => {
    if (onProductClick) {
      // Pass both product ID and variation ID
      onProductClick(_id, variationId);
    }
  };

  return (
    <div className="product-card">
      <div className="card-header">
        {renderStockBadge()}
        {discount > 0 && (
          <div className="discount-badge">
            <span>{discount}% OFF</span>
          </div>
        )}
      </div>

      <div className="image-container" onClick={handleProductClick}>
        {imageUrls && imageUrls.length > 0 ? (
          <img
            src={imageUrls[0]}
            alt={name}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              e.target.nextElementSibling.style.display = 'flex';
            }}
          />
        ) : (
          <div className="image-placeholder">
            <div className="placeholder-icon">📷</div>
            <p>Image not available</p>
          </div>
        )}
      </div>

      <div className="product-content">
        <h3 className="product-title" onClick={handleProductClick}>
          {name}
        </h3>
        
        {/* <p className="product-description">
          {description || baseDescription}
        </p> */}

        {/* {showDetails && (
          <div className="product-details desktop-only">
            <div className="specs-row">
              {volume && (
                <div className="spec-item">
                  <span className="spec-label">Size</span>
                  <span className="spec-value">
                    {volume}
                    {hasOtherVariations && ' (More options available)'}
                  </span>
                </div>
              )}
              {fragrance && fragrance !== "Na" && (
                <div className="spec-item fragrance">
                  <span className="spec-label">Fragrance</span>
                  <span className="spec-value">{fragrance}</span>
                </div>
              )}
            </div>
          </div>
        )} */}

<div className="price-block">
        <div className="price-info">
          <div className="current-price">
            <span className="currency">₹</span>
            <span className="amount">
              {discount > 0 ? formattedDiscountedPrice : formattedPrice}
            </span>
          </div>
          {discount > 0 && (
            <div className="original-price">
            <span className="mrp-section">
              <span className="currency">MRP </span>
              <span className="currency">₹</span>
              <span className="amount">{formattedPrice}</span>
            </span>
            <div className="tax-info">
              (incl. of all taxes)
            </div>
          </div>
          )}
        </div>
        {isInStock && (
          <div className="shipping-info">
            <span>Free Delivery</span>
          </div>
        )}
      </div>
      <Bought/>

        <div className="action-buttons">
          <button 
            className={`secondary ${!isInStock ? 'disabled' : ''}`}
            onClick={handleAddToCart}
            disabled={!isInStock || isLoading}
            aria-label="Add to cart"
          >
            <BsCartPlus className="button-icon" />
            <span>Add to Cart</span>
          </button>
          <button 
            className={`primary ${!isInStock || isLoading ? 'disabled' : ''}`}
            onClick={handleBuyNow}
            disabled={!isInStock || isLoading}
            aria-label="Buy now"
          >
            <BsLightningCharge className="button-icon" />
            <span>{isLoading ? 'Processing...' : 'Buy Now'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;