import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import CartSidebar from '../CartPages/CartPage';
import SignInModal from '../Signin_up/Signin';
import CustomIcon from '../../utils/CustomIcon';
import { useAuth } from '../Signin_up/AuthContext';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/Navbar.scss';
import '../../styles/CartPage.scss';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';

const AnimatedBanner = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const messages = [
    "🔖 Extra 5% OFF on Prepaid Payment",
    "🚛 Free Shipping",
    "🚛 Fast Delivery"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const bannerStyle = {
    background: 'linear-gradient(to right, #066469, #066469)',
    color: 'white',
    padding: '8px',
    overflow: 'hidden',
    position: 'relative', // Changed from 'sticky' to 'relative'
    minHeight: '36px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "'Libre Bodoni', serif"
  };

  const messageStyle = (isActive) => ({
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '600',
    fontFamily: "'Libre Bodoni', serif",
    transition: 'all 0.5s ease-in-out',
    transform: isActive ? 'translateY(0)' : 'translateY(-100%)',
    opacity: isActive ? 1 : 0,
    lineHeight: '20px'
  });

  return (
    <div style={bannerStyle}>
      {messages.map((message, index) => (
        <div
          key={index}
          style={messageStyle(index === currentMessage)}
        >
          {message}
        </div>
      ))}
    </div>
  );
};

const UserMenu = ({ onClose }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="navbar__mobile-menu__user-menu">
      <div className="navbar__mobile-menu__user-info">
        <span className="navbar__mobile-menu__user-mobile">{user?.mobileNumber}</span>
      </div>
      <button 
        onClick={() => {
          navigate('/orders');
          onClose();
        }}
        className="navbar__mobile-menu__menu-item"
      >
        My Orders
      </button>
      <button 
        onClick={handleLogout}
        className="navbar__mobile-menu__menu-item"
      >
        Logout
      </button>
    </div>
  );
};

const ResponsiveNavbar = () => {
  const { isCartOpen, openCart, closeCart, toggleCart } = useCartUI();
  const [showIntro, setShowIntro] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);useEffect(() => {
    const handleClickOutside = (event) => {
      if (showUserMenu && !event.target.closest('.navbar__action-btn')) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showUserMenu]);

  useEffect(() => {
    const timer = setTimeout(() => setShowIntro(false), 5);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const handleCartUpdate = (event) => setCart(event.detail.cart);
    const handleStorageChange = () => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCart(storedCart);
    };

    window.addEventListener('cartUpdate', handleCartUpdate);
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('cartUpdate', handleCartUpdate);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  
  const handleUserClick = () => {
    if (isAuthenticated) {
      setShowUserMenu(!showUserMenu);
    } else {
      setIsSignInOpen(true);
    }
  };

  return (
    <>
      {showIntro && (
        <div className="intro-overlay">
          <img src="https://ik.imagekit.io/iafgy06va/malak.png?updatedAt=1737543407799" alt="Malak Logo" className="intro-logo" />
        </div>
      )}

      <div className={showIntro ? 'hidden' : ''}>
        <AnimatedBanner />
      </div>

      <nav className={`navbar ${showIntro ? 'hidden' : ''} ${isMobile ? 'navbar--mobile' : ''}`}>
        <div className="navbar__container">
          <button 
            className="navbar__sidebar-toggle" 
            onClick={toggleSidebar}
            aria-label="Toggle sidebar"
          >
            <div className="icon-container">
              {isSidebarOpen ? (
                <CloseIcon className="rotating-enter" />
              ) : (
                <FormatListBulletedIcon className="rotating-enter" />
              )}
            </div>
          </button>

          <div className="navbar__logo">
            <Link to="/">
              <img 
                src="/malak.png" 
                alt="Malak Logo" 
                className={`navbar__logo-img ${showIntro ? '' : 'animated-logo'}`} 
              />
            </Link>
          </div>

          {!isMobile && (
            <div className="navbar__links">
              <Link to="/" className="navbar__link">Home</Link>
              <Link to="/products" className="navbar__link">Products</Link>
              <Link to="/about" className="navbar__link">About</Link>
              <Link to="/contact" className="navbar__link">Contact</Link>
            </div>
          )}

          <div className="navbar__actions">
            <button 
              onClick={handleUserClick}
              className="navbar__action-btn"
              aria-label={isAuthenticated ? "User menu" : "Sign in"}
            >
              <CustomIcon name="user" className="navbar__icon navbar__user-icon-svg" />
              {showUserMenu && isAuthenticated && (
                <UserMenu onClose={() => setShowUserMenu(false)} />
              )}
            </button>

            <button 
              className="navbar__action-btn" 
              onClick={toggleCart}
              aria-label="Shopping cart"
            >
              <CustomIcon name="shopping" className="navbar__icon navbar__cart-icon-svg" />
              {cart.length > 0 && (
                <span className="cart-count">
                  {cart.length}
                </span>
              )}
            </button>
          </div>
        </div>
      </nav>

      <Sidebar 
  isOpen={isSidebarOpen} 
  onClose={() => setIsSidebarOpen(false)}
  onOpenSignIn={() => setIsSignInOpen(true)}
/>
      <CartSidebar />
      <SignInModal
        isOpen={isSignInOpen}
        onClose={() => setIsSignInOpen(false)}
        onLoginSuccess={() => {
          setIsSignInOpen(false);
        }}
      />
    </>
  );
};

export default ResponsiveNavbar;