import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/ProductShowcase.scss'; 

const FeatureIcon = ({ iconClass, text }) => (
  <div className="feature-icon">
    <div className={`icon-wrapper ${iconClass}`}></div>
    <span>{text}</span>
  </div>
);

const Bubble = ({ onPop, index }) => {
  const [popped, setPopped] = useState(false);
  const bubbleRef = useRef(null);

  useEffect(() => {
    const bubble = bubbleRef.current;
    const duration = 5000 + Math.random() * 5000;
    const delay = index * 1000;

    bubble.style.animationDuration = `${duration}ms`;
    bubble.style.animationDelay = `${delay}ms`;

    const timer = setTimeout(() => {
      setPopped(true);
      onPop();
    }, duration + delay);

    return () => clearTimeout(timer);
  }, [onPop, index]);

  return (
    <div 
      ref={bubbleRef}
      className={`bubble ${popped ? 'popped' : ''}`} 
      style={{
        left: `${Math.random() * 80 + 10}%`,
      }}
    ></div>
  );
};

const ProductShowcase = () => {
  const showcaseRef = useRef(null);
  const [bubbles, setBubbles] = useState([]);
  const [bubbleCount, setBubbleCount] = useState(0);
  const [showFormulation, setShowFormulation] = useState(false);
  const navigate = useNavigate();

  // Default product and variation IDs from the data
  const defaultProductId = "6744819219aecc37a125eb29"; // Main product ID
  const defaultVariationId = "6744819219aecc37a125eb2a"; // First variation ID (1 LTR)

  const handleQuickView = () => {
    navigate(`/products?category=LAUNDRY CARE`, {
      state: { 
        selectedProductId: defaultProductId,
        selectedVariationId: defaultVariationId
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          showcaseRef.current.classList.add('animate-in');
          setTimeout(() => setShowFormulation(true), 1000);
        }
      },
      { threshold: 0.1 }
    );

    if (showcaseRef.current) {
      observer.observe(showcaseRef.current);
    }

    return () => {
      if (showcaseRef.current) {
        observer.unobserve(showcaseRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBubbles(prevBubbles => [...prevBubbles, bubbleCount]);
      setBubbleCount(prevCount => prevCount + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [bubbleCount]);

  const handleBubblePop = () => {
    console.log('Pop!');
  };

  return (
    <div className="product-showcase" ref={showcaseRef}>
      <div className="content-wrapper">
        <div className="product-info">
          <h1 className="title">Malak Laundry Liquid Detergent</h1>
          <div className="category">
            <div className="icon home-icon"></div>
            <span>HOME CARE</span>
          </div>
          <h2 className="product-name">Thick Liquid Detergent</h2>
          <p className="description">
            HIGH ACTIVE-MATTER WITH
            6 BLEND OF BIO ENZYMES.
          </p>
          <div className="features">
            <FeatureIcon iconClass="factory-icon" text="MADE WITH GERMAN FORMULATION TECHNOLOGY" />
            <FeatureIcon iconClass="leaf-icon" text="FRESH SCENT" />
            <FeatureIcon iconClass="clock-icon" text="LONG LASTING CLEAN" />
            <FeatureIcon iconClass="color-icon" text="COLOUR REMAINS BRIGHT AS A NEW" />
            <FeatureIcon iconClass="droplet-icon" text="COMPLETE COLOUR PROTECTION TECHNOLOGY" />
          </div>
          <button className="shop-now" onClick={handleQuickView}>
            SHOP NOW
          </button>
        </div>
      </div>
      <div className="image-wrapper">
        <img src="https://ik.imagekit.io/srwardmxk/5LTRB_inFAVPhK00.png" alt="Malak Bathroom Cleaner" className="product-image" />
        <div className="glow-effect"></div>
        {showFormulation && (
          <div className="formulation-line">
            <div className="formulation-dot"></div>
          </div>
        )}
        <div className="bubbles">
          {bubbles.map((id) => (
            <Bubble key={id} onPop={handleBubblePop} index={id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;