import React, { useState, useRef } from 'react';
import { Loader2 } from 'lucide-react';
import '../../styles/SingleBanner.scss';

const SingleBanner = ({ 
  videoUrl, 
  width = '98%',
  height = '800px'
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const handleVideoError = () => {
    setIsLoading(false);
    setVideoError(true);
  };

  return (
    <div className="single-banner-container" 
      style={{ 
        padding: '0 0 24px 0',
        margin: '0 auto',
        width: '100%',
        maxWidth: '1400px'
      }}>
      <div 
        className="banner-wrapper"
        style={{ 
          width,
          margin: '0 auto',
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden'
        }}
      >
        <div 
          className="banner-content"
          style={{ 
            height: 'auto',
            maxHeight: height,
            position: 'relative',
            backgroundColor: '#f3f4f6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            aspectRatio: '16/9'
          }}
        >
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Loader2 className="animate-spin text-gray-500" size={32} />
            </div>
          )}
          
          {videoError ? (
            <div className="absolute inset-0 flex items-center justify-center text-gray-500">
              Failed to load video
            </div>
          ) : (
            <video
              ref={videoRef}
              src={videoUrl}
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleVideoLoad}
              onError={handleVideoError}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              className={`transition-opacity duration-300 ${
                isLoading ? 'opacity-0' : 'opacity-100'
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;