import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause } from 'lucide-react';
import '../../styles/ImageSlider.scss';

const ImageSlider = ({ desktopImages = [], mobileImages = [], interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const images = isMobile ? mobileImages : desktopImages;

  useEffect(() => {
    if (images.length === 0 || !isPlaying) return;
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval, isPlaying]);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const diff = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (Math.abs(diff) > minSwipeDistance) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
  };

  const goToSlide = (index) => setCurrentIndex(index);
  const handlePrev = () => setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  const handleNext = () => setCurrentIndex((prev) => (prev + 1) % images.length);
  const togglePlayPause = () => setIsPlaying(!isPlaying);

  if (images.length === 0) {
    return (
      <div className="image-slider-wrapper">
        <div className="image-slider-container empty">
          <p>No Images Available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="image-slider-wrapper">
      <div 
        className="image-slider-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`image-slider-slide ${index === currentIndex ? 'active' : ''}`}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className={index === currentIndex ? 'active' : ''}
              loading="lazy"
            />
          </div>
        ))}

        <div className="image-slider-controls">
        {!isMobile && (
  <div className="image-slider-navigation">
    <button onClick={handlePrev} aria-label="Previous slide">
      <ChevronLeft />
    </button>
    <button onClick={handleNext} aria-label="Next slide">
      <ChevronRight />
    </button>
  </div>
)}

          {/* <button 
            className="image-slider-playpause"
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause slideshow' : 'Play slideshow'}
          >
            {isPlaying ? <Pause /> : <Play />}
          </button> */}

          {/* <div className="image-slider-counter">
            {currentIndex + 1} / {images.length}
          </div> */}

          <div className="image-slider-dots">
            {images.map((_, index) => (
              <button
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>

          <div className="image-slider-progress">
            <div
              className="image-slider-progress-fill"
              style={{
                width: `${((currentIndex + 1) / images.length) * 100}%`
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;