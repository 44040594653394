import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';
import { toast } from 'react-toastify';
import AddressForm from '../order/addressForm';
import LoginModal from '../Signin_up/Signin';
import { useAuth } from '../Signin_up/AuthContext';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';

const calculateDiscount = (subtotal, coupon) => {
  if (!coupon) return 0;
  return coupon.type === 'percentage' 
    ? subtotal * (coupon.discount / 100)
    : Math.min(coupon.discount, subtotal);
};

const validateCheckoutData = (checkoutData) => {
  if (!checkoutData) return false;
  
  const requiredFields = {
    cart: Array.isArray(checkoutData.cart) && checkoutData.cart.length > 0,
    pricing: checkoutData.pricing && 
             typeof checkoutData.pricing.total === 'number' &&
             typeof checkoutData.pricing.subtotal === 'number',
    address: checkoutData.address &&
             checkoutData.address.name &&
             checkoutData.address.street &&
             checkoutData.address.city &&
             checkoutData.address.state &&
             checkoutData.address.pincode
  };
  
  return Object.values(requiredFields).every(field => field === true);
};

const createFreshCheckoutData = (cart, selectedCoupon, address) => {
  const subtotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);
  const discount = calculateDiscount(subtotal, selectedCoupon);
  const total = Math.max(0, subtotal - discount);

  return {
    cart: cart.map(item => ({
      productId: item.productId,
      variationId: item.variationId,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
      variationName: item.variationName,
      _id: item._id
    })),
    pricing: {
      subtotal,
      discount,
      total
    },
    coupon: selectedCoupon,
    address: address
  };
};

const CartSidebar = () => {
  const { isCartOpen, closeCart } = useCartUI();
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cartTimer, setCartTimer] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  
  // Available (visible) coupons
  const [availableCoupons] = useState([
    { id: 1, code: 'SAVE10', discount: 10, type: 'percentage' },
    { id: 2, code: 'FLAT50', discount: 50, type: 'fixed' },
    { id: 3, code: 'SUMMER20', discount: 20, type: 'percentage' },
  ]);

  // Hidden coupons (special/custom coupons)
  const hiddenCoupons = [
    { id: 4, code: 'WELCOME50', discount: 50, type: 'percentage', minAmount: 1000 },
    { id: 5, code: 'FIRSTORDER', discount: 200, type: 'fixed', minAmount: 500 },
    { id: 6, code: 'SPECIAL25', discount: 25, type: 'percentage', minAmount: 2000 }
  ];


  useEffect(() => {
    // Only proceed if user is logged in and cart has items
    if (isAuthenticated && cart.length > 0) {
      // Clear any existing timer
      if (cartTimer) {
        clearTimeout(cartTimer);
      }
      
      // Set new timer for 2 minutes
      const timer = setTimeout(async () => {
        try {
          const config = {
            method: 'post',
            url: 'https://backend.aisensy.com/campaign/t1/api/v2',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: {
              apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2RiMTc3ZTQ4MjFhMGJmZTQyMDhjYiIsIm5hbWUiOiJCdXltYWxhay5pbiIsImFwcE5hbWUiOiJBaVNlbnN5IiwiY2xpZW50SWQiOiI2NzEzMzRlYWQ3OTA4NjBiOTdjYTQxMTAiLCJhY3RpdmVQbGFuIjoiTk9ORSIsImlhdCI6MTczMjA5NjM3NX0.VKJNyMu9XM3l5hIxNDiHFp6nZ2tbnh0cR2A4hnz8DTM",
              campaignName: "welcome",
              destination: "7828504154",
              userName: "Shahrukh",
              source: "Postman Test",
              templateParams: []
            }
          };

          const response = await axios.request(config);
          console.log('Notification sent:', JSON.stringify(response.data));
        } catch (error) {
          console.error('Error sending notification:', error);
        }
      }, 120000); // 2 minutes in milliseconds
      
      setCartTimer(timer);

      // Cleanup function
      return () => {
        if (cartTimer) {
          clearTimeout(cartTimer);
        }
      };
    }
  }, [isAuthenticated, cart.length]);

  useEffect(() => {
    const loadCartFromStorage = () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      } catch (error) {
        console.error('Error loading cart from storage:', error);
      }
    };

    loadCartFromStorage();
  }, []); 

  const updateLocalStorage = (updatedCart) => {
    try {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  };

  useEffect(() => {
    const loadRazorpay = async () => {
      if (document.querySelector('script[src="https://checkout.razorpay.com/v1/magic-checkout.js"]')) {
        setRazorpayLoaded(true);
        return;
      }
  
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/magic-checkout.js';
      script.async = true;
      script.onload = () => setRazorpayLoaded(true);
      document.body.appendChild(script);
    };
  
    loadRazorpay();
  }, []);

  const syncCartWithLocalStorage = () => {
    try {
      // This function should now only read from localStorage, not write to it
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      return storedCart;
    } catch (error) {
      console.error('Error syncing cart:', error);
      return [];
    }
  };

  useEffect(() => {
    syncCartWithLocalStorage();
  }, []);

  

   const handleLoginSuccess = async (response) => {
    try {
      const checkoutData = createFreshCheckoutData(cart, selectedCoupon, null);
      localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
      
      setShowLoginModal(false);
      setShowAddressForm(true);
      closeCart();
    } catch (error) {
      console.error('Error handling login success:', error);
      toast.error('Error processing checkout. Please try again.');
    }
  };

  

  

  useEffect(() => {
    if (isAuthenticated) {
      try {
        const storedCheckoutData = JSON.parse(localStorage.getItem('checkoutData'));
        
        // Restore coupon if it existed
        if (storedCheckoutData?.coupon) {
          setSelectedCoupon(storedCheckoutData.coupon);
        }
      } catch (error) {
        console.error('Error restoring cart data after login:', error);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isCartOpen) {
      setShowAddressForm(false);
      setShowLoginModal(false);
    }
  }, [isCartOpen]);

  useEffect(() => {
    if (isCartOpen) {
      document.body.classList.add('cart-open');
    } else {
      document.body.classList.remove('cart-open');
    }
    return () => {
      document.body.classList.remove('cart-open');
    };
  }, [isCartOpen]);

  useEffect(() => {
    // Load cart from localStorage when component mounts
    const loadInitialCart = () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        if (storedCart.length > 0) {
          // Instead of trying to add items one by one, just update the localStorage
          localStorage.setItem('cart', JSON.stringify(storedCart));
        }
      } catch (error) {
        console.error('Error loading initial cart:', error);
      }
    };
  
    loadInitialCart();
  }, []); 

  const validateCoupon = (code, cartTotal) => {
    // Check both available and hidden coupons
    const coupon = [...availableCoupons, ...hiddenCoupons].find(
      c => c.code === code.toUpperCase()
    );
    
    if (!coupon) {
      return { valid: false, message: 'Invalid coupon code' };
    }

    if (coupon.minAmount && cartTotal < coupon.minAmount) {
      return { 
        valid: false, 
        message: `This coupon requires a minimum purchase of ₹${coupon.minAmount}`
      };
    }

    return { valid: true, coupon };
  };

  const handleCouponSubmit = (e) => {
    e.preventDefault();
    
    if (!couponInput.trim()) {
      toast.error('Please enter a coupon code');
      return;
    }

    if (cart.length === 0) {
      toast.error('Please add products to your cart before applying a coupon');
      return;
    }

    const cartTotal = getSubtotal();
    const { valid, message, coupon } = validateCoupon(couponInput, cartTotal);

    if (valid) {
      setSelectedCoupon(coupon);
      setCouponInput('');
      toast.success(`Coupon ${coupon.code} applied successfully!`);
    } else {
      toast.error(message);
    }
  };

  const applyCoupon = (coupon) => {
    if (cart.length === 0) {
      toast.error('Please add products to your cart before applying a coupon');
      return;
    }

    const cartTotal = getSubtotal();
    if (coupon.minAmount && cartTotal < coupon.minAmount) {
      toast.error(`This coupon requires a minimum purchase of ₹${coupon.minAmount}`);
      return;
    }

    setSelectedCoupon(coupon);
    toast.success(`Coupon ${coupon.code} applied successfully!`);
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
    toast.info('Coupon removed');
  };

  const getSubtotal = () => {
    if (cart.length === 0) return 0;
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getDiscountAmount = () => {
    if (!selectedCoupon || cart.length === 0) return 0;
    const subtotal = getSubtotal();
    
    if (selectedCoupon.type === 'percentage') {
      return subtotal * (selectedCoupon.discount / 100);
    } else {
      return Math.min(selectedCoupon.discount, subtotal);
    }
  };

  const getTotalPrice = () => {
    if (cart.length === 0) return "0.00";
    const subtotal = getSubtotal();
    const discount = getDiscountAmount();
    return Math.max(0, subtotal - discount).toFixed(2); 
  };

  // const handleAddressSubmit = async (formData) => {
  //   try {
  //     setIsProcessing(true);
      
  //     const shippingAddress = {
  //       name: formData.fullName,
  //       phone: formData.phone,
  //       email: formData.email,
  //       address: `${formData.address1} ${formData.address2}`.trim(),
  //       city: formData.city,
  //       state: formData.state,
  //       pincode: formData.pincode,
  //       country: 'IN'
  //     };
  
  //     const finalTotal = parseFloat(getTotalPrice());
  //     const items = cart.map(item => ({
  //       productId: item.productId,
  //       variationId: item.variationId,
  //       quantity: item.quantity,
  //       price: item.price,
  //       name: item.name,
  //       description: item.description || item.name,
  //       imageUrl: item.imageUrl?.[0] || '',
  //       sku: `SKU-${item.productId}-${item.variationId || ''}`
  //     }));
  
  //     const response = await axios.post('/magic-checkout/create', {
  //       items,
  //       shippingAddress,
  //       totalAmount: finalTotal,
  //       couponCode: selectedCoupon?.code
  //     });
  
  //     if (!response.data.success) {
  //       throw new Error(response.data.message || 'Failed to create order');
  //     }
  
  //     const razorpayOptions = {
  //       ...response.data.razorpayConfig,
  //       handler: async function(response) {
  //         try {
  //           let verificationResponse;
            
  //           if (response.razorpay_payment_id) {
  //             verificationResponse = await axios.post('/order/verify', {
  //               razorpayOrderId: response.data.razorpayOrderId,
  //               razorpayPaymentId: response.razorpay_payment_id,
  //               razorpaySignature: response.razorpay_signature
  //             });
  //           } else if (response.payment_method === 'cod') {
  //             verificationResponse = await axios.post('/order/verify', {
  //               razorpayOrderId: response.data.razorpayOrderId,
  //               paymentMethod: 'cod'
  //             });
  //           }
  
  //           if (verificationResponse.data.success) {
  //             clearCart();
  //             localStorage.removeItem('cart');
              
  //             toast.success(
  //               response.payment_method === 'cod'
  //                 ? 'Order placed successfully! You can pay at the time of delivery.'
  //                 : 'Payment successful!'
  //             );
              
  //             navigate('/checkout/order-success', {
  //               state: {
  //                 orderId: response.data.orderId,
  //                 paymentId: response.razorpay_payment_id || 'COD'
  //               }
  //             });
  //           }
  //         } catch (error) {
  //           console.error('Payment verification error:', error);
  //           toast.error('Order verification failed');
  //           navigate('/checkout/failed');
  //         }
  //       },
  //       modal: {
  //         ondismiss: function() {
  //           setIsProcessing(false);
  //         },
  //         confirm_close: true,
  //         escape: false
  //       }
  //     };
  
  //     if (!window.Razorpay) {
  //       throw new Error('Razorpay SDK not loaded');
  //     }
  
  //     const razorpayInstance = new window.Razorpay(razorpayOptions);
  //     razorpayInstance.open();
      
  //   } catch (error) {
  //     console.error('Checkout error:', error);
  //     toast.error(error.message || 'Checkout failed. Please try again.');
  //     setIsProcessing(false);
  //   }
  // };

  // In CartSidebar.js

  const handleAddressSubmit = async (selectedAddress) => {
    if (!selectedAddress || !selectedAddress.fullName) {
      toast.error('Please select a valid address');
      return;
    }
    closeCart(); // Close cart before navigation
  
    try {
      // Get current cart state
      const currentCart = JSON.parse(localStorage.getItem('cart')) || [];
      if (currentCart.length === 0) {
        throw new Error('Cart is empty');
      }
  
      // Calculate totals
      const subtotal = currentCart.reduce((total, item) => total + (item.price * item.quantity), 0);
      const discount = selectedCoupon ? calculateDiscount(subtotal, selectedCoupon) : 0;
      const total = Math.max(0, subtotal - discount);
  
      // Format address
      const formattedAddress = {
        name: selectedAddress.fullName,
        street: selectedAddress.address1 + (selectedAddress.address2 ? ', ' + selectedAddress.address2 : ''),
        city: selectedAddress.city,
        state: selectedAddress.state,
        pincode: selectedAddress.pincode,
        phone: selectedAddress.phone,
        email: selectedAddress.email,
        type: selectedAddress.type
      };
  
      // Create checkout data
      const checkoutData = {
        cart: currentCart.map(item => ({
          productId: item.productId,
          variationId: item.variationId,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
          variationName: item.variationName,
          _id: item._id
        })),
        pricing: {
          subtotal,
          discount,
          total
        },
        coupon: selectedCoupon,
        address: formattedAddress
      };
  
      // Validate checkout data
      const isValid = checkoutData.cart?.length > 0 && 
                     checkoutData.pricing?.total > 0 && 
                     checkoutData.address?.name && 
                     checkoutData.address?.street;
  
      if (!isValid) {
        throw new Error('Invalid checkout data');
      }
  
      // Save and proceed
      localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
      navigate('/checkout/address');
    } catch (error) {
      console.error('Error processing address submission:', error);
      toast.error('Error processing checkout. Please try again.');
    }
  };

  const validateCheckoutData = (checkoutData) => {
    if (!checkoutData) return false;
    
    const requiredFields = {
      cart: Array.isArray(checkoutData.cart) && checkoutData.cart.length > 0,
      pricing: checkoutData.pricing && 
               typeof checkoutData.pricing.total === 'number' &&
               typeof checkoutData.pricing.subtotal === 'number',
      address: checkoutData.address &&
               checkoutData.address.name &&
               checkoutData.address.street &&
               checkoutData.address.city &&
               checkoutData.address.state &&
               checkoutData.address.pincode
    };
    
    return Object.values(requiredFields).every(field => field === true);
  };

  const refreshCheckoutData = (cart, selectedCoupon, address) => {
    const subtotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    const discount = selectedCoupon ? calculateDiscount(subtotal, selectedCoupon) : 0;
    const total = Math.max(0, subtotal - discount);
  
    return {
      cart: cart.map(item => ({
        productId: item.productId,
        variationId: item.variationId,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
        variationName: item.variationName,
        _id: item._id
      })),
      pricing: {
        subtotal,
        discount,
        total
      },
      coupon: selectedCoupon,
      address: address
    };
  };

  const checkAuthAndProceed = () => {
    if (!isAuthenticated) {
      setShowLoginModal(true);
    } else {
      setShowAddressForm(true);
    }
  };

  return (
    <>
      {isCartOpen && <div className="cart-sidebar-overlay" onClick={closeCart}></div>}
      <div className={`cart-sidebar ${isCartOpen ? 'open' : ''}`}>
        {showAddressForm && isAuthenticated ? (
          <AddressForm 
            onSubmit={handleAddressSubmit}
            onClose={() => setShowAddressForm(false)}
            existingAddresses={[]}
          />
        ) : (
          <>
            <div className="cart-sidebar-header">
              <h2>Your Cart</h2>
              <button 
                onClick={closeCart} 
                className="close-btn"
                aria-label="Close cart"
              >✕</button>
            </div>

            <div className="cart-sidebar-items">
              {cart.length === 0 ? (
                <div className="empty-cart">
                  <span className="cart-icon">🛒</span>
                  <p>Your cart is empty</p>
                </div>
              ) : (
                cart.map((item) => (
                  <div key={`${item._id}-${item.variationId || ''}`} className="cart-item">
                    <img 
                      src={item.imageUrl && item.imageUrl[0]} 
                      alt={item.name} 
                      className="item-image"
                      onError={(e) => {
                        e.target.src = '/placeholder-image.jpg';
                      }}
                    />
                    <div className="item-details">
                      <h3>{item.name}</h3>
                      {item.variationName && (
                        <p className="variation-name">{item.variationName}</p>
                      )}
                      <p className="item-price">₹{item.price.toLocaleString('en-IN')}</p>
                      <div className="quantity-control">
                        <button 
                          onClick={() => updateQuantity(item.productId, item.variationId, -1)}
                          aria-label="Decrease quantity"
                        >-</button>
                        <span>{item.quantity}</span>
                        <button 
                          onClick={() => updateQuantity(item.productId, item.variationId, 1)}
                          aria-label="Increase quantity"
                        >+</button>
                      </div>
                    </div>
                    <button 
                      onClick={() => removeFromCart(item.productId, item.variationId)}
                      className="remove-btn"
                      aria-label={`Remove ${item.name} from cart`}
                    >
                      🗑️
                    </button>
                  </div>
                ))
              )}
            </div>

            <div className="cart-sidebar-coupons mt-4">
              {/* Available Coupons Section */}
              <div className="available-coupons mb-4">
                <h3 className="text-lg font-semibold mb-2">Available Coupons</h3>
                {cart.length === 0 ? (
                  <p className="text-sm text-gray-500">Please add products to your cart to apply coupons</p>
                ) : (
                  <div className="grid gap-2">
                    {availableCoupons.map((coupon) => (
                      <button
                        key={coupon.id}
                        onClick={() => applyCoupon(coupon)}
                        className={`coupon-btn p-2 border rounded-md text-left ${
                          selectedCoupon?.id === coupon.id 
                          ? 'bg-blue-50 border-blue-500' 
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      <div className="font-medium">{coupon.code}</div>
                      <div className="text-sm text-gray-600">
                        {coupon.type === 'percentage' 
                          ? `${coupon.discount}% off` 
                          : `₹${coupon.discount} off`}
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Custom Coupon Input Section */}
            <div className="custom-coupon-section">
              <h3 className="text-lg font-semibold mb-2">Have a different coupon?</h3>
              <form onSubmit={handleCouponSubmit} className="flex gap-2">
                <input
                  type="text"
                  value={couponInput}
                  onChange={(e) => setCouponInput(e.target.value.toUpperCase())}
                  placeholder="Enter coupon code"
                  className="w-full px-3 py-2 border rounded-md"
                />
                <button 
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Apply
                </button>
              </form>
            </div>

            {/* Applied Coupon Display */}
            {selectedCoupon && (
              <div className="applied-coupon mt-4 p-3 bg-green-50 border border-green-200 rounded-md">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="font-medium">Applied: {selectedCoupon.code}</span>
                    <span className="text-sm text-gray-600 ml-2">
                      {selectedCoupon.type === 'percentage' 
                        ? `${selectedCoupon.discount}% off`
                        : `₹${selectedCoupon.discount} off`}
                    </span>
                  </div>
                  <button 
                    onClick={removeCoupon}
                    className="text-red-600 hover:text-red-700"
                    aria-label="Remove coupon"
                  >
                    ✕
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Cart Footer with Totals */}
          <div className="cart-sidebar-footer mt-4">
            <div className="subtotal flex justify-between py-2">
              <span>Subtotal:</span>
              <span>₹{getSubtotal().toLocaleString('en-IN')}</span>
            </div>
            {selectedCoupon && (
              <div className="discount flex justify-between py-2 text-green-600">
                <span>Discount:</span>
                <span>-₹{getDiscountAmount().toLocaleString('en-IN')}</span>
              </div>
            )}
            <div className="total flex justify-between py-2 font-bold text-lg">
              <span>Total:</span>
              <span>₹{parseFloat(getTotalPrice()).toLocaleString('en-IN')}</span>
            </div>
            <button
              className="checkout-btn w-full mt-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={checkAuthAndProceed}
              disabled={cart.length === 0 || isProcessing}
              aria-label={isProcessing ? 'Processing checkout' : 'Proceed to checkout'}
            >
              {isProcessing ? 'Processing...' : 'Proceed to Checkout'}
            </button>
          </div>
        </>
      )}
    </div>

    <LoginModal
      isOpen={showLoginModal}
      onClose={() => setShowLoginModal(false)}
      onLoginSuccess={handleLoginSuccess}
    />
  </>
);
};

export default CartSidebar;