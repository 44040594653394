import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from '../../helpers/axiosconfig';
import '../../styles/orders.scss';

// Helper function to handle image display
const getProductImage = (item) => {
  return item.product?.imageUrl || '/placeholder-image.jpg';
};

// Components remain the same
const StarRating = ({ rating }) => (
  <div className="star-rating">
    {[1, 2, 3, 4, 5].map((star) => (
      <span key={star} className={`star ${star <= rating ? 'filled' : ''}`}>
        ★
      </span>
    ))}
  </div>
);

const ChevronLeft = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

const ChevronRight = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const OrdersList = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      const response = await instance.get('/user/orders');
      
      if (response.data.success) {
        setOrders(response.data.orders);
      } else {
        throw new Error(response.data.message || 'Failed to fetch orders');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error fetching orders:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleTrackStatus = (orderId) => {
    navigate(`tracking/${orderId}`);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(price);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getStatusIcon = (status) => {
    const icons = {
      pending: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M12 6v6l4 2" />
        </svg>
      ),
      delivered: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M8 12l3 3 6-6" />
        </svg>
      ),
      processing: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M12 6v2M12 16v2M6 12h2M16 12h2" />
        </svg>
      ),
      cancelled: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M15 9l-6 6M9 9l6 6" />
        </svg>
      ),
      completed: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M8 12l3 3 6-6" />
        </svg>
      )
    };
    return icons[status] || icons.pending;
  };

  if (loading) {
    return (
      <div className="orders-dashboard">
        <div className="loading-state">Loading orders...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="orders-dashboard">
        <div className="error-state">
          <p>Error loading orders: {error}</p>
          <button onClick={fetchOrders} className="retry-button">
            Retry
          </button>
        </div>
      </div>
    );
  }

  const filteredOrders = orders.filter(order => {
    const matchesStatus = filterStatus === 'all' || order.status === filterStatus;
    const matchesSearch = order.orderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         order?.shippingAddress?.name?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  return (
    <div className="orders-dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="header-title">
            <div className="header-icon">
              <svg viewBox="0 0 24 24">
                <path d="M21 8l-3-3H6L3 8m18 0v12H3V8m18 0H3m9 4h.01" />
              </svg>
            </div>
            <h1>My Orders</h1>
          </div>

          <div className="header-controls">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search orders..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <svg viewBox="0 0 24 24">
                  <circle cx="11" cy="11" r="8" />
                  <path d="M21 21l-4.35-4.35" />
                </svg>
              </span>
            </div>

            <select
              className="filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Orders</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
        </div>
      </header>

      <div className="orders-grid">
        {filteredOrders.length === 0 ? (
          <div className="no-orders">
            <p>No orders found</p>
          </div>
        ) : (
          filteredOrders.map((order) => (
            <div key={order.orderId} className="order-card">
              <div className="order-header">
                <div className="customer-details">
                  <div className="customer-avatar">
                    {order.shippingAddress?.name?.charAt(0) || 'U'}
                  </div>
                  <div className="customer-info">
                    <h3>{order.shippingAddress?.name || 'Unknown Customer'}</h3>
                    <span className="order-date">
                      <svg className="calendar-icon" viewBox="0 0 24 24">
                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                        <line x1="16" y1="2" x2="16" y2="6" />
                        <line x1="8" y1="2" x2="8" y2="6" />
                        <line x1="3" y1="10" x2="21" y2="10" />
                      </svg>
                      {formatDate(order.orderDate)}
                    </span>
                  </div>
                </div>

                <div className="order-meta">
                  <div className={`status-badge ${order.status}`}>
                    {getStatusIcon(order.status)}
                    <span>{order.status}</span>
                  </div>
                  <div className="order-total">{formatPrice(order.totalAmount)}</div>
                </div>
              </div>

              <div className="order-items">
                {order.items.map((item, index) => (
                  <div key={index} className="item-row">
                    <div className="item-info">
                      <div className="item-image-container">
                        <img 
                          src={getProductImage(item)}
                          alt={item.product?.name || 'Product Image'}
                          className="item-image"
                          onError={(e) => {
                            e.target.src = '/placeholder-image.jpg';
                            e.target.onerror = null;
                          }}
                        />
                      </div>
                      <div className="item-details">
                        <span className="item-name">
                          {item.product?.name || 'Product'}
                        </span>
                        <div className="item-meta">
                          <span className="item-quantity">
                            Quantity: {item.quantity}
                          </span>
                          {item.product?.volume && (
                            <span className="item-volume">
                              Volume: {item.product.volume}
                            </span>
                          )}
                          <span className="item-unit-price">
                            {formatPrice(item.price)} each
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="item-total-price">
                      {formatPrice(item.price * item.quantity)}
                    </span>
                  </div>
                ))}
              </div>

              <div className="order-footer">
                <div className="order-summary">
                  <div className="summary-row">
                  </div>
                  <div className="summary-row">
                    <span>Payment Status:</span>
                    <span className={`payment-status ${order.paymentStatus}`}>
                      {order.paymentStatus}
                    </span>
                  </div>
                  <div className="summary-row">
                  </div>
                </div>

                <div className="order-actions">
                  <button 
                    className="track-status-btn"
                    onClick={() => handleTrackStatus(order.orderId)}
                  >
                    Track Status
                    <svg className="arrow-icon" viewBox="0 0 24 24">
                      <path d="M9 18l6-6-6-6" />
                    </svg>
                  </button>
                  
                  <div className="secondary-actions">
                    <button className="download-btn">
                      <svg viewBox="0 0 24 24">
                        <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" />
                      </svg>
                      Invoice
                    </button>
                    <button 
                      className="details-btn"
                      onClick={() => navigate(`/order/${order.orderId}`)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OrdersList;