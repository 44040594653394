// CustomerAttractionStrip.jsx
import React, { useState, useEffect } from 'react';
import '../../styles/CustomerAttractionStrip.scss';

const phrases = [
  { text: "Fast delivery", icon: "⚡" },
  { text: "Extra discount on prepaid orders", icon: "🛡️" },
  { text: "Free delivery", icon: "💡" },
  { text: "Cod available", icon: "🍃" },
];

const CustomerAttractionStrip = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="customer-attraction-strip">
      <div className="strip-content">
        <div className="phrases-container">
          {phrases.map((phrase, index) => (
            <div
              key={index}
              className="phrase"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`
              }}
            >
              <span className="icon">{phrase.icon}</span>
              <span>{phrase.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerAttractionStrip;