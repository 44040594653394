import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    try {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      if (storedCart.length > 0) {
        setCart(storedCart);
        window.dispatchEvent(new CustomEvent('cartUpdate', {
          detail: { cart: storedCart }
        }));
      }
    } catch (error) {
      console.error('Error loading cart from storage:', error);
      setCart([]);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(cart));
      window.dispatchEvent(new CustomEvent('cartUpdate', {
        detail: { cart }
      }));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  }, [cart]);

  const addToCart = (item) => {
    setCart(prevCart => {
      const existingItemIndex = prevCart.findIndex(
        cartItem => 
          cartItem.productId === item.productId && 
          cartItem.variationId === item.variationId
      );

      if (existingItemIndex > -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: updatedCart[existingItemIndex].quantity + (item.quantity || 1)
        };
        toast.success(`${item.name} updated in cart`);
        return updatedCart;
      }

      const newItem = { ...item, quantity: item.quantity || 1 };
      toast.success(`${item.name} added to cart`);
      return [...prevCart, newItem];
    });
  };

  const removeFromCart = (productId, variationId) => {
    setCart(prevCart => {
      const itemToRemove = prevCart.find(item => 
        item.productId === productId && item.variationId === variationId
      );
      const updatedCart = prevCart.filter(item => 
        !(item.productId === productId && item.variationId === variationId)
      );
      
      if (itemToRemove) {
        toast.info(`${itemToRemove.name} removed from cart`);
      }
      return updatedCart;
    });
  };

  const updateQuantity = (productId, variationId, change) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map(item => {
        if (item.productId === productId && item.variationId === variationId) {
          const newQuantity = Math.max(1, item.quantity + change);
          const updatedItem = { ...item, quantity: newQuantity };
          
          if (newQuantity === 1 && change < 0) {
            toast.info(`Minimum quantity reached for ${item.name}`);
          } else if (change > 0) {
            toast.success(`Added one more ${item.name}`);
          } else if (change < 0) {
            toast.info(`Removed one ${item.name}`);
          }
          
          return updatedItem;
        }
        return item;
      }).filter(item => item.quantity > 0);
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
    localStorage.removeItem('checkoutData'); // Clear checkout data as well
    toast.info('Cart cleared');
  };

  return (
    <CartContext.Provider value={{
      cart,
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};