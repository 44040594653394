import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  CheckCircle, 
  ShoppingBag, 
  Assignment 
} from '@mui/icons-material';
import '../../styles/OrderSuccess.scss';

const OrderSuccess = () => {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = "ORD123456";  // In real app, this would come from props/state
  
  useEffect(() => {
    // Replace current history entry with products page
    window.history.replaceState(null, '', '/products');
    setTimeout(() => setShowContent(true), 100);
  }, []);

  const handleViewOrders = () => {
    setTimeout(() => {
      navigate('/orders');
    }, 200);
  };

  const handleContinueShopping = () => {
    setTimeout(() => {
      navigate('/');
    }, 200);
  };

  return (
    <div className="order-success-container">
      <div className={`success-card ${showContent ? 'show' : ''}`}>
        <div className="success-content">
          {/* Custom Animated Success Icon */}
          <div className="success-icon-wrapper">
            <div className="success-icon">
              <div className="success-icon-circle"></div>
              <div className="success-icon-check"></div>
            </div>
          </div>

          {/* Success Message */}
          <div className={`message-section ${showContent ? 'show' : ''}`}>
            <h1 className="title">Order Placed Successfully!</h1>
            
            <div className="order-id-box">
              <p className="order-id">
                Order ID: <span>{orderId}</span>
              </p>
            </div>

            <p className="confirmation-text">
              Thank you for your purchase! You can see the order tracking in order history.
            </p>
          </div>

          {/* Action Buttons */}
          <div className={`button-group ${showContent ? 'show' : ''}`}>
            <button 
              className="primary-button"
              onClick={handleViewOrders}
            >
              <Assignment className="button-icon" />
              <span>View Orders</span>
            </button>
            
            <button 
              className="secondary-button"
              onClick={handleContinueShopping}
            >
              <ShoppingBag className="button-icon" />
              <span>Continue Shopping</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;