import React from 'react';
import { ShoppingBag } from 'lucide-react';

const ProductEngagement = () => {
  return (
    <div className="flex items-center p-3 mb-4 bg-gray-50 rounded-lg">
      <div className="flex items-center gap-2">
        <ShoppingBag className="w-5 h-5 text-emerald-500" />
        <span className="text-sm text-gray-700">
          <strong className="font-semibold">1.8k</strong> people bought this
        </span>
      </div>
    </div>
  );
};

export default ProductEngagement;